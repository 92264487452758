import JSZip from "jszip";
import FileSaver from "file-saver";//引入依赖包

let zipBatch:any = {};//方法名，调用时候用
let fileIndex = 0; //轮循脚标，轮循一次，fileIndex++
let fileList:any[] = [];  //存放转化完成的文件的数组
let arrSource:any[] = []; //原始数据
let blogTitle = ''; //压缩包的名称
let mainFileBlob:any;
zipBatch.zip = (arr:any,title:any,mainFile:Blob) =>{
  //参数arr是原始数据，每个元素两个属性 path 文件链接 ，title 文件名称
  //参数title是压缩完成的压缩包名
  fileList = [];
  arrSource = arr;
  blogTitle = title;
  fileIndex = 0;
  mainFileBlob=mainFile;
  zipBatch.addLoop();
}
zipBatch.addLoop = () =>{
  let submitIndex = fileIndex;
  if (submitIndex >= arrSource.length) { //轮询结束开始压缩
    var zip = new JSZip();
    var promises = [];

    //文件夹
    if(fileList&&fileList.length>0){
      let folders:string[]=[];
      fileList.forEach(s=>{
        if(!folders.includes(s.folder)){
          folders.push(s.folder);
        }
      })
      folders.forEach(folder=>{
        let fold=zip.folder(folder) as any;
        fileList.filter(s=>s.folder==folder).forEach(item => {
          let file_name = item.title  //若下载其他文件此处后缀名就改成其他后缀名
          fold.file(file_name, item.binary, { binary: true }); // 逐个添加文件
        })
      })
    }

    //主文件处理
    if(mainFileBlob){
      let file = new File([mainFileBlob], blogTitle+'.doc', {type: mainFileBlob.type, lastModified: Date.now()});
      zip.file(blogTitle+'.doc', file, { binary: true }); // 逐个添加文件
    }


    zip.generateAsync({ type: "blob" }).then((content) => {
      // 生成二进制流
      FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  blogTitle:自定义文件名
    });
    return;
  }
  let item = arrSource[submitIndex];

  zipBatch.addCore( item, function () {
    fileIndex++;
    zipBatch.addLoop();
  });

}
zipBatch.addCore = (item:any, finallyFun:any) =>{
  //文件以流的形式获取
  // 方法1
  let xmlhttp = new XMLHttpRequest();
  xmlhttp.withCredentials = false;
  xmlhttp.open("GET", item.path, true);
  xmlhttp.responseType = "blob";

  xmlhttp.onload = function () {
    if (xmlhttp.status == 200) {
      fileList.push({
        binary: xmlhttp.response, // 文件
        title: item.title, // 文件名称
        folder:item.folder
      })
      finallyFun()
    } else {
      // 获取失败处理
    }
  };
  xmlhttp.send();
  // 方法2
  // fetch(item.path).then((response) => response.blob())
  // .then((blob) => {
  // 	//将blob对象转换为file并返回
  // 	let file = new File([blob], title, {type: blob.type, lastModified: Date.now()});
  // 	fileList.push({
  // 		binary: file, // 文件链接
  // 		title: item.path // 文件名称
  // 	})
  // })

}
export default zipBatch;
