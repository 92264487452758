
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import api from '@/api/index';
import {OrganizationUnitDto} from "@/api/appService";

@Component({name: 'SelectUserOrgMapping'})
export default class SelectUserOrgMapping extends Vue {


  @Prop({required: true, default: 0})
  projectId!: number;

  @Prop({required: true, default: 0})
  mappingIds!: any[];

  show: boolean = false;
  submitting = false;

  organizationUnits = [];

  private allOrganizationUnits: OrganizationUnitDto[] = [];

  async created() {


    await this.loadOrganizationUnits();

  }

  async loadOrganizationUnits() {
    await api.organizationUnit.getAll({maxResultCount: 65535}).then(res => {
      this.allOrganizationUnits = res.items!;
    });
  }

  async loadMapping() {
    await api.project.getProjectMappings({projectId: this.projectId})
  }

  @Watch('projectId')
  onProjectIdChange(newProjectId: number) {
    this.loadMapping();
  }

  handleSubmit() {
    this.submitting = true;
    api.projectBeneficiaryMapping.batchDistributionOrg({
      body: {
        mappingIds: this.mappingIds,
        orgIds: this.organizationUnits ?? 0
      }
    }).then(res => {
      this.$message.success('更新成功');
      this.submitting = false;
      this.show = false;
    }).catch(res => {
      this.submitting = false;
    })
  }


}
