
import {Component, Ref, Vue} from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import {
  AttachmentHostType,
  AuditFlowScope,
  CustomFormDto,
  CustomFormFieldDto,
  CustomFormType,
  FamilyMembersInfoDto
} from "@/api/appService";
import api from '@/api/index';
import {AuditBodyDto} from "@/utils/customDto";
// import ExportWord from "@/components/DownloadWord/index.vue";
import ExportWord from "@/components/DownloadZipFile/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {ZipFileInfo, ZipFileInput} from "@/utils/htmlToZipFile/commonDto";

@Component({
  name: 'CustomFormStandardDetail',
  components: {
    CustomFormDataTableDetail,
    ExportWord,
    AttachmentsView,
    AuditButtons
  }
})
export default class CustomFormStandardDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  private formId!: string;
  private dataId!: string;
  private hostType: CustomFormType = CustomFormType.Beneficiary;
  private isProject = false;
  isReady = false;

  styles: string = "";
  selector: string = "detail-wrap"
  filename: string = "项目受益人详情";
  projectName: string | undefined = '';
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ProjectBebeficiaryMapping,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }
  familyMembersInfoList: FamilyMembersInfoDto[] = [];
  otherFiles: ZipFileInput[] = [];

  created() {
    this.formId = this.$route.query.formId as string;
    this.dataId = this.$route.query.id as string;
    this.isProject = false;

    if (this.dataId && this.hostType) {
      this.isReady = true;
    } else {
      this.$message.error('缺少url参数 id 或 hostType，请检查!');
    }

    this.fetchAuditDetail();
  }

  fetchAuditDetail() {
    api.projectBeneficiaryMapping.get({id: parseInt(this.dataId)}).then(res => {
      this.projectName = res.project?.displayName;
      this.familyMembersInfoList = res.familyMembersInfoList ?? [];
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }

      //自定义表单中附件处理
      api.customFormService.get({id: this.formId as any}).then(async (cres: CustomFormDto) => {
        let imageFields = cres.fields?.filter(s => s.elementType == 'upload-batch-image') as CustomFormFieldDto[];
        if (imageFields && imageFields.length > 0) {
          var ext = res.extensionObject as any;
          if (ext) {
            for (const key of Object.keys(ext)) {
              if (key.indexOf('upload-batch-image') > -1) {
                //处理附件
                const field = imageFields.find(s => s.fieldName == key)
                if (field) {
                  const files = await api.attachmentService.getAttachments({
                    hostId: `${this.dataId}:${field.id}`,
                    hostType: AttachmentHostType.Beneficiary
                  })
                  if (files && files.length > 0) {
                    //创建附件文件夹
                    let folderFiles = files.map(f => {
                      return {
                        title: f.fileName,
                        path: f.url
                      } as ZipFileInfo
                    })
                    this.otherFiles.push({
                      folder: field.fieldDisplayName!,
                      files: folderFiles
                    })

                  }
                }
              }

            }
          }
        }

      })
    })
  }

  getData() {
    return api.projectBeneficiaryMapping.get({id: parseInt(this.dataId)});
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.projectBeneficiaryMapping.audit;
  }

  changeAudit() {
    this.fetchAuditDetail()
  }
}
