import { saveAs } from 'file-saver'
import zipBatch from "@/utils/htmlToZipFile/zipBatch";
import {ZipFileInput} from "@/utils/htmlToZipFile/commonDto";

function getModelHtml(mhtml:any, style:string) {
  return`
      <!DOCTYPE html>
      <html>
      <head>
      <meta http-equiv="Content-Type" content="text/html;charset=utf-8"/>
      <style>
        ${style}
      </style>
      </head>
      <body>
        ${mhtml}
      </body>
      </html>
    `
}
interface ExportWordArguments {
  mhtml?:any;
  style?:any;
  filename?:string,
  data?:string,
  selector:string
  otherFiles?:ZipFileInput[]
}
export function exportWord(args:ExportWordArguments) {
  if(typeof module !== 'undefined'){
    window.saveAs = require('file-saver')
  }

  if(args.selector){
    let nodes = window.document.querySelectorAll(args.selector)
    args.mhtml = nodes.length>0?Array.from(nodes).reduce((a,b)=>a+b.innerHTML,''):''
  }

  if (typeof saveAs === "undefined") {
    console.error("wordExport : missing dependency (FileSaver.js)");
    return;
  }
  let html = getModelHtml(args.mhtml,args.style)
  console.log("Html="+JSON.stringify(html))
  let blob = new Blob([html],{type:'application/msword;charset=utf-8'})
  // saveAs(blob,args.filename+'.doc')

  let arrSource:any[] = [];
  //拼接数据源
  if(args.otherFiles&&args.otherFiles.length>0){
    args.otherFiles!.forEach((item, index) => {
      item.files.forEach(file=>{
        arrSource.push({
          path: file.path, // 文件链接
          title: file.title,// 文件名称
          folder:item.folder
        })
      })
    })
  }
  console.log(arrSource);
  debugger
  zipBatch.zip(arrSource, args.filename,blob)
}
