
import {Component, Vue,} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  AuditFlowScope,
  BeneficiaryDto,
  DataDictionaryDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import SelectUserOrgMapping
  from "@/views/project/internalProject/projectProposal/beneficiary/selectUserOrgMapping.vue";
import VueExtension from "@/core/core";

@Component({
  name: "internalProjectProposalBeneficiaryList",
  components: {
    SelectUserOrgMapping,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
    ProvinceCityArea,
    ImportExcel
  }
})
export default class InternalProjectProposalBeneficiaryList extends Vue {
  dataId!: number;
  formId!: number;
  projectId!: number;
  queryForm = {
    code: undefined,
    beneficiaryName: undefined,
    projectId: 0,
    sex: undefined,
    minAge: undefined,
    maxAge: undefined,
    nation: undefined,
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    townId: undefined,
    isEnjoyTheSubsistenceAllowancePolicy: undefined,
    isEnjoyOtherSalvation: undefined
  };
  selectionList: any[] = [];
  mappingIds: any[] = [];

  sexList: DataDictionaryDto[] = []
  nationList: DataDictionaryDto[] = []

  created() {
    if (this.$route.query.id) {
      this.dataId = Number(this.$route.query.id);
    }
    if (this.$route.query.formId) {
      this.formId = Number(this.$route.query.formId);
    }
    if (this.$route.query.projectId) {
      this.queryForm.projectId = this.projectId = Number(
        this.$route.query.projectId
      );
    }

    this.fetchDataDictionary();
    this.fetchNationDataDictionary();
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = [];
    this.mappingIds = [];
    this.selectionList = rows
    this.selectionList.forEach(res => {
      this.mappingIds.push(res.id)
    })

    console.log(this.mappingIds)
  }

  fetchData(params: any) {
    if (this.$route.query.projectId) {
      params.projectId = Number(
        this.$route.query.projectId
      );
    }
    return api.projectBeneficiaryMapping.getAll(params);
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  fetchNationDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'Nation', maxResultCount: 1000})
      .then((res: any) => {
        this.nationList = res.items
      })
  }


  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.index, $row.item);
        break;
      case "delete":
        this.handleDelete($row.index, $row.item);
        break;
      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "internalProjectProposalBeneficiaryCreate",
      query: {
        formId: String(this.formId),
        projectId: String(this.projectId)
      }
    });
  }

  handleAdd() {
    this.$router.push({
      name: "internalProjectProposalBeneficiaryAdd",
      query: {
        formId: String(this.formId),
        projectId: String(this.projectId)
      }
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "internalProjectProposalBeneficiaryEdit",
      query: {
        formId: String(this.formId),
        projectId: String(this.projectId),
        id: row.id,
        beneficiaryId: row.beneficiaryId
      }
    });
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "internalProjectProposalBeneficiaryDetail",
      query: {
        id: row.id,
        formId: String(this.formId)
      }
    });
  }

  handleAuditFlow(index: number, row: any) {
    debugger
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ProjectBebeficiaryMapping,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: BeneficiaryDto) {
    await api.projectBeneficiaryMapping
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  // 删除
  async handleDelete(index: number, row: BeneficiaryDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.projectBeneficiaryMapping.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleShowSelectOrg() {
    if (this.mappingIds && this.mappingIds.length > 0) {
      (this.$refs.selectUserOrgMapping as any).show = true;
    } else {
      this.$message.error('请选择受益人');
    }
  }

  cancel() {
    this.$router.back();
  }

  hasPermission(permissionCode: string) {
    return (this as unknown as VueExtension).hasPermission(permissionCode);
  }
}
